html {
  background-color: #282c34;
  color: aliceblue;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@supports(padding:max(0px)) {
  body, header, footer {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

.col, .row {
  display: flex;
  /* outline: 0.5px aliceblue solid; */
  flex: 1;
  /* width: 100%; */
  /* max-height: 44px; */
}

.row {
  flex-direction: row;
  max-height: 44px;
}

.row.reverse {
  flex-flow: row-reverse;
}

.col {
  flex-direction: column;
  width: 100%;
}

.col.reverse {
  flex-flow: column-reverse;
}

aside {
  padding: 12px
}

.full-height{
  height:100%
}

.full-width {
  width: 100%;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  position: absolute;

  z-index: 999;
  top: -5px;
  left: 120%;
}

.mainbox {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.money-box {
  border-radius: 14px;
}

.is-rounded {
  border-radius: 14px;
}

.is-outlined-dark {
  border: 1px solid hsl(0, 0%, 48%)
}

.is-bordered {
  border: 1px solid hsl(0, 0%, 48%) !important
}

.border-danger {
  border-color: hsl(348, 86%, 43%)	!important
}

.border-success {
  border-color:hsl(141, 53%, 31%) !important
}

.is-chosen {
  border-color: hsl(204, 86%, 53%) !important;
  background: hsl(206, 70%, 96%)	 !important;
}

.border-grey {
  border-color: hsl(0, 0%, 86%)  !important
}

.border-gold {
  border-color: hsl(48, 100%, 67%)	 !important
}

.border-info {
  border-color: hsl(204, 71%, 39%)	 !important
}

.no-shadow {
  box-shadow: none !important;
}

.is-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.bottomout {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu-list a:hover {
  background-color: hsl(0, 0%, 96%);
  border-radius: 14px;
}

.menu-list .is-selected a:hover  {
  background-color: hsl(171, 100%, 29%);
  border-radius: 14px;
}

.center-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.make-fit {
  max-width: 95% !important;
  margin: auto;
}

.no-padding {
  padding: 0;
}